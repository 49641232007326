<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="main-container">
    <div class="container">
      <div class="banner-container" v-if="mobile">
        <div class="logout-banner" v-if="sessionStarted !== 0">
          <div class="logout-icon" @click="logout">
            Log Out
            <img
              src="../assets/Icons/exit-logout.png"
              alt="logout icon"
            />
          </div>
        </div>
        <div class="logo-practice-banner">
          <div class="logo-container">
            <img class="logo"
              v-if="logoData"
              :src="logoData"
              alt="Practice Logo"
            />
          </div>
          <div class="practice-name-container"
            v-if="nameData">
            {{ nameData }}
          </div>
        </div>
      </div>
      <div class="instructions">
        Confirm Appointment
      </div>
      <div class="confirmation-container">
        <div class="progress-bar">
          <div class="progress-meter-1"></div>
          <div class="progress-meter-2"></div>
          <div class="progress-meter-3"></div>
        </div>
        <div class="tab"></div>
          <NewAppointmentCard />
      </div>
      <div class="footer">
        Chirowrite Online Scheduler © 2024
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import NewAppointmentCard from './widgets/NewAppointmentCard.vue';

export default {
  components: {
    NewAppointmentCard,
  },
  data() {
    return {
      windowWidth: null,
      mobile: null,
      tablet: null,
      desktop: null,
    };
  },
  methods: {
    logout() {
      console.log('Logged Out. Good bye 👋');
      localStorage.clear();
      store.state.sessionID = 0;
      store.state.conOffice = '';
      store.state.conDoctor = '';
      store.state.conService = '';
      store.state.conOfficeID = '';
      store.state.conServiceID = '';
      store.state.conDoctorID = '';
      store.state.conDate = '';
      store.state.conShortDate = '';
      store.state.conDay = '';
      store.state.conTime = '';
      store.state.conSendDate = '';
      this.$router.push('/');
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth >= 960) {
        this.desktop = true;
        this.tablet = false;
        this.mobile = false;
      } else if (this.windowWidth < 960 && this.windowWidth >= 640) {
        this.tablet = true;
        this.desktop = false;
        this.mobile = false;
      } else {
        this.mobile = true;
        this.desktop = false;
        this.tablet = false;
      }
    },
  },
  computed: {
    logoData() {
      return this.$store.state.conPracticeLogo;
    },
    nameData() {
      return this.$store.state.conPracticeName;
    },
    sessionStarted() {
      return this.$store.state.sessionID;
    },
  },
  created() {
    window.addEventListener('resize', this.checkScreen);
    this.checkScreen();
  },
};
</script>

<style lang="scss" scoped>

.progress-bar {
  width: 100%;
  height: 15px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 5px;
}

.progress-meter-1 {
  width: 90%;
  height: 10px;
  border-radius: 15px;
  background-color: #00589c;
}

.progress-meter-2 {
  width: 90%;
  height: 10px;
  border-radius: 15px;
  background-color: #00589c;
}

.progress-meter-3 {
  width: 90%;
  height: 10px;
  border-radius: 15px;
  background-color: #b3b3b3;
}
.confirmation-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  height:60%;
  border-radius: 20px;
  background-color: #fff;
  @media (width >=40em) {
    width: 50%;
  }
}
</style>
